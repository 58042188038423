<style scoped>
.poll {

}
.poll-img {
  max-height: 200px;
  width: 100px;
  /* display: none; */
}
</style>
<template>
<div class="row bg-light p-2 rounded" style="margin-right: 0;margin-left: 0;">
  <div class="p-4 p-md-5 border rounded-3 bg-light col-lg-6 col-md-12 mx-auto">
    <h3 class="display-7 fw-bold lh-1 mb-3">
    {{question}}
    </h3>
    <ul class="list-group">
      <li class="list-group-item" v-for="(it, idx) in options"
      :key="idx"
      :class="{active: choice==idx}"
      @click="voteClicked(idx)">
        <p class="col-lg-10 fs-5 text-center">
          <img
            class="poll-img pt-3 "
            :src="it.media"
            :alt="it.answer"
          >
          <br>
          {{it.answer}}
        </p>
      </li>
    </ul>
  </div>
</div>

</template>
<script>
export default {
  data() {
    return {
      choice: -1
    };
  },
  props: {
    pollid: {
      type: String,
      required: true,
      default: ""
    },
    question: {
      type: String,
      required: true,
      default: ""
    },
    options: {
      type: Array,
      default() {
        return [
        // {
        //   media: "https://www.fillmurray.com/400/400",
        //   answer: "Los cazafantasmas",
        //   votes: 1
        // },
        // {
        //   media: "https://www.fillmurray.com/400/401",
        //   answer: "Lost in Translation",
        //   votes: 2
        // },
        // {
        //   media: "https://www.fillmurray.com/399/400",
        //   answer: "Angeles de Charlie",
        //   votes: 3
        // }
        ];
      }
    },
    voteDisabled: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    voteClicked(idx) {
      const { answer } = this.options[idx];
      this.$swal({
        icon: "question",
        title: `¿Votar por ${answer}?`,
        showDenyButton: true,
        confirmButtonText: "¡Votar!",
        denyButtonText: " No ",
      }).then(res => {
        if (res.isConfirmed) {
          this.$emit("onVote", this.pollid, idx);
        }
      });

      this.$emit("onTryVote", this.pollid, idx);
    }
  },
  mounted() {}
};
</script>
